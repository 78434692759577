import {
  LinkOutlined,
  MailOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import outlinePayments from "@iconify-icons/ic/outline-payments";
import { InlineIcon } from "@iconify/react";
import { App, Button, FormInstance, MenuProps } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter } from "found";
import React, { useState } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import {
  PROJECT_INCENTIVE_TYPES,
  PROJECT_STATUSES,
  recruitStatusMap,
  recruitTypeMap,
  RECRUIT_STATUSES,
} from "../../constants";
import { ScreenersRecruitTypeChoices } from "../../schema";
import { GRAY_7, HEADER_FONT_SIZE, HEADER_FONT_WEIGHT, PRIMARY_TEXT_COLOR } from "../../style";
import {
  getRecruitContext,
  getStudyContext,
  getTenantContext,
  mutation,
  onError,
  trackEvent,
  useConfirm,
  useFlag,
} from "../../utils";
import { usdFormatter } from "../../utils/misc";
import { RecruitTitle_recruit$data } from "../../__generated__/RecruitTitle_recruit.graphql";
import { RecruitTitle_study$data } from "../../__generated__/RecruitTitle_study.graphql";
import { NotificationLink } from "../Notifications/Notification";
import { OverflowButton } from "../OverflowBtn";
import { showLaunchModal } from "../Project/ProjectPage";

import { EditInvitationEmailModal } from "./EditInvitationEmailModal";
import RecruitNameModal from "./RecruitNameModal";

type Props = {
  defaultName: string;
  recruit: RecruitTitle_recruit$data;
  study: RecruitTitle_study$data;
  deleteRecruit: (recruitId: string) => void;
  isRecruitFormComplete: () => boolean;
  isRecruitFunded: () => boolean;
  setShowPaymentModal: React.Dispatch<React.SetStateAction<boolean>>;
  startRecruitMutation: () => any;
};
const RecruitTitle = React.forwardRef<FormInstance, Props>(
  (
    {
      defaultName,
      recruit,
      study,
      deleteRecruit,
      isRecruitFormComplete,
      isRecruitFunded,
      setShowPaymentModal,
      startRecruitMutation,
    },
    childFormRef
  ) => {
    const [showRecruitNameModal, setShowRecruitNameModal] = useState<boolean>(false);
    const [showEditInvitationEmailModal, setShowEditInvitationEmailModal] = useState(false);
    const { router } = useRouter();

    const confirm = useConfirm();
    const { message, modal, notification } = App.useApp();

    const ffHasRecruitChanges = useFlag("hub-recruit-page-changes-21-01-25");

    const startRecruit = async () => {
      const form = (childFormRef as any)?.current;
      try {
        if (form) {
          await form?.validateFields();
        }
      } catch {
        message.warning("Please complete the form");
        return;
      }

      if (!isRecruitFunded()) {
        setShowPaymentModal(true);
      } else {
        try {
          return await startRecruitMutation();
        } catch (err: any) {
          if (err?.errors?.[0]?.message?.includes("No new participants")) {
            modal.warning({
              title: "No valid participants",
              content: (
                <>
                  <p>No valid participants were added to this recruiting round.</p>
                  <p>
                    Please review the .csv file and verify that all emails are valid and belong to people who haven't
                    participated in other recruiting rounds for this project.
                  </p>
                </>
              ),
            });
          } else {
            onError(err, message);
            throw err;
          }
        }
      }
    };

    const pauseRecruit = () => {
      const optimisticResponse = recruit.id
        ? {
            pauseRecruit: {
              recruit: {
                id: recruit.id,
                status: RECRUIT_STATUSES.PAUSED,
              },
            },
          }
        : undefined;

      return mutation({
        variables: { input: { recruitId: recruit.id } },
        mutation: graphql`
          mutation RecruitTitle_pauseRecruit_Mutation($input: PauseRecruitInput!) {
            pauseRecruit(input: $input) {
              recruit {
                id
                status
              }
            }
          }
        `,
        optimisticResponse,
      }).then(() => {
        notification.success({
          message: "Recruiting round paused successfully",
          description: (
            <>
              Respondents can still complete the screener.You can see them in the{" "}
              <NotificationLink router={router} to={`/projects/${study.id}/respondents/participants`}>
                Participants tab
              </NotificationLink>
              .
            </>
          ),
        });
        trackEvent("Recruiting Paused", {
          ...getRecruitContext(recruit),
          ...getStudyContext(study),
          ...getTenantContext(study.tenant as any),
        });
      });
    };

    const resumeRecruit = () => {
      const optimisticResponse = recruit.id
        ? {
            pauseRecruit: {
              recruit: {
                id: recruit.id,
                status: RECRUIT_STATUSES.STARTED,
              },
            },
          }
        : undefined;

      return mutation({
        variables: { input: { recruitId: recruit.id } },
        mutation: graphql`
          mutation RecruitTitle_resumeRecruit_Mutation($input: ResumeRecruitInput!) {
            resumeRecruit(input: $input) {
              recruit {
                id
                status
              }
            }
          }
        `,
        optimisticResponse,
      }).then(() => {
        trackEvent("Recruiting Resumed", {
          "Study dID": study?.dId,
          "Study Type": study?.type,
          "Recruit Type": recruit?.type,
          "Recruit Name": recruit?.name,
          "Tenant Name": study?.tenant?.name,
        });
      });
    };

    const clickRecruit = async () => {
      if (study.status === PROJECT_STATUSES.DRAFT) {
        if (
          await confirm({
            title: "Your project is still in draft.",
            content: "To proceed, we need to set the project to Active.",
            okText: "Set Project Active",
          })
        )
          showLaunchModal({
            confirm,
            study,
            onSuccess: startRecruit,
          });
      } else if (recruit.status === RECRUIT_STATUSES.STARTED) {
        pauseRecruit();
      } else if (recruit.status === RECRUIT_STATUSES.PAUSED) {
        if (
          recruit.type === ScreenersRecruitTypeChoices.Wl ||
          (await confirm({
            title: "Resume recruiting round",
            content: "Are you sure you're ready to resume this recruiting round?",
            okText: "Resume Recruiting Round",
          }))
        )
          resumeRecruit();
      } else if (
        recruit.type === ScreenersRecruitTypeChoices.Wl ||
        (await confirm({
          title: "Start recruiting round",
          content: "Are you sure you're ready to start this recruiting round?",
          okText: "Start Recruiting Round",
        }))
      )
        startRecruit();
    };

    const clickDelete = async () => {
      if (
        await confirm({
          title: `Delete ${recruit.name}`,
          content: "Are you sure? Any invitees will be deleted.",
          okText: "Delete",
          okType: "danger",
          cancelText: "Cancel",
          maskClosable: true,
        })
      )
        deleteRecruit(recruit.id);
    };

    const Title = () => {
      let color = "";

      if (recruit.status === RECRUIT_STATUSES.PAUSED) {
        color = "yellow";
      } else if (recruit.status === RECRUIT_STATUSES.STARTED) {
        color = "blue";
      } else if (recruit.status === RECRUIT_STATUSES.FINISHED) {
        color = "green";
      } else {
        color = "gray";
      }

      return recruit.type ? (
        <div className="titles">
          <h2>{recruit.name || defaultName}</h2>
          {recruit.type === ScreenersRecruitTypeChoices.Wl ? (
            <LinkOutlined className="recruit-icon" />
          ) : recruit.type === ScreenersRecruitTypeChoices.Em ? (
            <MailOutlined className="recruit-icon" />
          ) : recruit.type === ScreenersRecruitTypeChoices.Hp ? (
            <TeamOutlined className="recruit-icon" />
          ) : (
            <UserOutlined className="recruit-icon" />
          )}
          <div className="recruit-type">{recruitTypeMap[recruit.type]}</div>
          <div className="recruit-incentive">
            <InlineIcon className="recruit-incentive-icon" icon={outlinePayments} />
            {recruit.incentiveType === PROJECT_INCENTIVE_TYPES.POINTS
              ? `${recruit.incentivePoints}pts`
              : usdFormatter.format(parseFloat(recruit.incentive ?? ""))}
          </div>
          <div className={`recruit-status ${color}`}>{recruitStatusMap[recruit.status]}</div>
        </div>
      ) : null;
    };

    const canDelete =
      !recruit.type || (study.status !== PROJECT_STATUSES.COMPLETE && recruit.status === RECRUIT_STATUSES.NOT_STARTED);

    const canEditInviteEmail =
      recruit.type === ScreenersRecruitTypeChoices.Yp &&
      ![RECRUIT_STATUSES.STARTED, RECRUIT_STATUSES.FINISHED].includes(recruit.status);

    const items: MenuProps["items"] = [
      {
        key: "rename",
        label: "Rename",
        onClick: () => setShowRecruitNameModal(true),
      },
      {
        key: "customize-email",
        label: "Edit invitation email",
        onClick: () => setShowEditInvitationEmailModal(true),
        disabled: !canEditInviteEmail,
      },
      {
        key: "delete",
        label: "Delete",
        onClick: () => clickDelete(),
        disabled: !canDelete,
        danger: true,
      },
    ];

    return (
      <>
        {recruit.type && isRecruitFormComplete() ? (
          <Styled>
            <div
              className="root"
              style={{
                marginBottom: ffHasRecruitChanges ? "0px" : "16px",
                marginTop: ffHasRecruitChanges && recruit.status === RECRUIT_STATUSES.NOT_STARTED ? "0px" : "10px",
              }}
            >
              <Title />
              {study.status !== PROJECT_STATUSES.COMPLETE &&
                (recruit.status === RECRUIT_STATUSES.NOT_STARTED &&
                !(
                  [ScreenersRecruitTypeChoices.Wl, ScreenersRecruitTypeChoices.Hp] as (typeof recruit)["type"][]
                ).includes(recruit.type) ? (
                  <div className="buttons">
                    {isRecruitFormComplete() && (
                      <Button
                        className="recruit-status-button"
                        type="primary"
                        size="large"
                        icon={<PlayCircleOutlined />}
                        onClick={clickRecruit}
                      >
                        Start Recruiting Round
                      </Button>
                    )}
                    <OverflowButton menu={items} />
                  </div>
                ) : recruit.status === RECRUIT_STATUSES.PAUSED ? (
                  <div className="buttons">
                    <Button
                      className="recruit-status-button"
                      type="primary"
                      size="large"
                      icon={<PlayCircleOutlined />}
                      onClick={clickRecruit}
                    >
                      Resume Recruiting Round
                    </Button>
                    <OverflowButton menu={items} />
                  </div>
                ) : recruit.type !== ScreenersRecruitTypeChoices.Wl && recruit.status === RECRUIT_STATUSES.STARTED ? (
                  <div className="buttons">
                    <Button
                      className="recruit-status-button"
                      type="primary"
                      size="large"
                      icon={<PauseCircleOutlined />}
                      onClick={clickRecruit}
                    >
                      Pause Recruiting Round
                    </Button>
                    <OverflowButton menu={items} />
                  </div>
                ) : null)}
            </div>
            {recruit.description && (
              <p
                style={{
                  marginTop: ffHasRecruitChanges ? "0px" : "1em",
                }}
                className="description"
              >
                {recruit.description}
              </p>
            )}
          </Styled>
        ) : (
          <Styled>
            <div
              className="root"
              style={{
                marginBottom: ffHasRecruitChanges ? "-24px" : "16px",
                marginTop: ffHasRecruitChanges ? "0px" : "10px",
              }}
            >
              <div className="titles create-new-round">
                {!ffHasRecruitChanges && <h1 className="header-text">Create new recruiting round</h1>}
              </div>
              <OverflowButton menu={items} />
            </div>
            {recruit.description && <p className="description">{recruit.description}</p>}
          </Styled>
        )}
        <EditInvitationEmailModal
          tenant={study.tenant}
          recruit={recruit}
          visible={showEditInvitationEmailModal}
          setVisible={setShowEditInvitationEmailModal}
        />
        <RecruitNameModal
          onClose={() => setShowRecruitNameModal(false)}
          visible={showRecruitNameModal}
          placeholderName={defaultName}
          recruit={recruit}
        />
      </>
    );
  }
);

export default createFragmentContainer(RecruitTitle, {
  study: graphql`
    fragment RecruitTitle_study on StudyNode {
      id
      dId
      name
      incentiveType
      defaultIncentive
      defaultIncentivePoints
      type
      meetingLink
      schedulingType
      schedulingLink
      availabilitySlots {
        edges {
          node {
            id
            duration
          }
        }
      }
      tenant {
        requireStudyPayment
        name
        dId
        vpmAccountId
        name

        ...EditInvitationEmailModal_tenant
      }
      status
      dId
      name
      type
      status
    }
  `,
  recruit: graphql`
    fragment RecruitTitle_recruit on RecruitNode {
      id
      type
      name
      description
      status
      incentiveType
      incentivePoints
      incentive
      recruitTransactions {
        edges {
          node {
            id
          }
        }
      }
      dbId
      type
      name

      ...RecruitNameModal_recruit
      ...EditInvitationEmailModal_recruit
    }
  `,
});

const Styled = styled.div`
  width: 100%;

  .root {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .header-text {
      margin-bottom: 0;
      font-size: ${HEADER_FONT_SIZE};
      font-weight: ${HEADER_FONT_WEIGHT};
      color: ${PRIMARY_TEXT_COLOR};
    }

    .create-new-round {
      justify-content: center;
      width: 90%;
    }
    .titles {
      display: flex;
      align-items: center;

      h2 {
        margin: 0;
      }

      .recruit-icon {
        padding-left: 1.5rem;
        color: ${GRAY_7};
      }

      .recruit-incentive,
      .recruit-type {
        padding-left: 0.5rem;
        color: ${GRAY_7};
        font-size: 15px;

        .recruit-incentive-icon {
          margin-left: 0.5rem;
          margin-right: 0.4rem;
          vertical-align: middle;
        }
      }

      .recruit-status {
        padding-left: 1.5rem;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .buttons {
      display: flex;
      align-items: center;

      .recruit-status-button {
        margin-left: 0.5rem;
      }

      .overflow-button {
        margin-left: 1rem;
      }
    }

    .gray {
      color: ${GRAY_7};
    }

    .yellow {
      color: var(--ant-warning-color);
    }

    .blue {
      color: var(--ant-primary-color);
    }

    .green {
      color: var(--ant-success-color);
    }

    .red {
      color: var(--ant-error-color-active);
    }
  }

  .description {
    color: ${GRAY_7};
  }

  .red-border {
    border-color: var(--ant-error-color-active);
  }

  .anticon {
    line-height: 0;
  }

  .ant-btn {
    display: flex;
    align-items: center;
  }

  .ant-btn-background-ghost {
    color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
  }
`;
