import { GlobalOutlined, LinkOutlined, MailOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { graphql } from "babel-plugin-relay/macro";
import { useMemo } from "react";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { useAntdConfig } from "../../antd";

import { Tooltip } from "antd";
import { PROJECT_INCENTIVE_TYPES, recruitTypeMap, RECRUIT_STATUSES } from "../../constants";
import { ScreenersRecruitTypeChoices } from "../../schema";
import { BORDER_RADIUS, GRAY_6 } from "../../style";
import { mutation, useFlag } from "../../utils";
import { maybeElement } from "../../utils/misc";
import { RecruitTypePicker_recruit$data } from "../../__generated__/RecruitTypePicker_recruit.graphql";
import { RecruitTypePicker_study$data } from "../../__generated__/RecruitTypePicker_study.graphql";

const RecruitTypePicker = ({
  study,
  recruit,
}: {
  study: RecruitTypePicker_study$data;
  recruit: RecruitTypePicker_recruit$data;
}) => {
  const changeRecruitType = (type: string) => {
    const optimisticResponse = recruit.id
      ? {
          changeRecruitType: {
            recruit: {
              id: recruit.id,
              type,
            },
          },
        }
      : undefined;

    return mutation({
      variables: { input: { recruitId: recruit.id, recruitType: type } },
      mutation: graphql`
        mutation RecruitTypePickerMutation($input: ChangeRecruitTypeInput!) {
          changeRecruitType(input: $input) {
            recruit {
              id
              type
            }
          }
        }
      `,
      optimisticResponse,
    });
  };

  // A study can only have one HubUX recruit so we check if any recruits are already of that type
  const alreadyHasHubuxRound = !!(
    study.recruits.edges.find(edge => edge?.node?.type === ScreenersRecruitTypeChoices.Hp) &&
    recruit.type !== ScreenersRecruitTypeChoices.Hp
  );

  const hubuxPanelEnabled = useFlag("hub-recruit-type-hubux-panel");
  const ffHasRecruitChanges = useFlag("hub-recruit-page-changes-21-01-25");

  const recruitTypes = useMemo(
    () => [
      {
        key: ScreenersRecruitTypeChoices.Yp,
        label: recruitTypeMap[ScreenersRecruitTypeChoices.Yp],
        icon: <UserOutlined />,
        description: "Deploy to Community",
        disabled: null,
      },
      ...maybeElement(hubuxPanelEnabled, {
        key: ScreenersRecruitTypeChoices.Hp,
        label: recruitTypeMap[ScreenersRecruitTypeChoices.Hp],
        icon: <TeamOutlined />,
        description: "Find participants from the HubUX Panel who fit your criteria",
        disabled: null,
      }),
      {
        key: ScreenersRecruitTypeChoices.Wl,
        label: recruitTypeMap[ScreenersRecruitTypeChoices.Wl],
        icon: <LinkOutlined />,
        description: "Ideal for sharing via email, social media, etc.",
        disabled: null,
      },
      {
        key: ScreenersRecruitTypeChoices.Em,
        label: recruitTypeMap[ScreenersRecruitTypeChoices.Em],
        icon: <MailOutlined />,
        description: "Send to a list of email addresses",
        disabled: null,
      },
      ...maybeElement(study.tenant.voxpopmeGlobal, {
        key: ScreenersRecruitTypeChoices.Ro,
        label: recruitTypeMap[ScreenersRecruitTypeChoices.Ro],
        icon: <GlobalOutlined />,
        description: "Use VPM Global community to find participants",
        disabled: !recruit.canHaveVoxpopmeGlobal,
      }),
    ],
    [hubuxPanelEnabled, study.tenant.voxpopmeGlobal, recruit.canHaveVoxpopmeGlobal]
  );

  const configProviderProps = useAntdConfig();

  return (
    <div>
      <Styled $cols={recruitTypes.length}>
        <div className="source-title">{ffHasRecruitChanges ? "Recruit from..." : "Source"}</div>
        <div className="card-container">
          {recruitTypes.map((recruitType, i) => {
            const cardDisabled =
              (recruitType.key === ScreenersRecruitTypeChoices.Hp &&
                (alreadyHasHubuxRound ||
                  study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL ||
                  study.tenant.isDemoAccount)) ||
              (recruitType.disabled === true && recruitType.disabled !== null);

            let tooltipTitle = null;
            if (cardDisabled) {
              if (alreadyHasHubuxRound) {
                tooltipTitle =
                  "You already have a HubUX Panel recruit which will continue to send invites and update based on changes to your screener criteria.";
              } else if (study.incentiveType === PROJECT_INCENTIVE_TYPES.EXTERNAL) {
                tooltipTitle = "HubUX Panel recruiting rounds are unavailable for projects using external incentives.";
              } else if (recruitType.key === ScreenersRecruitTypeChoices.Ro) {
                tooltipTitle =
                  "Not available for multi-session diary studies. To run a multi-session, contact support@voxpopme.com";
              } else {
                tooltipTitle = "HubUX Panel recruiting rounds are unavailable for projects on demo accounts.";
              }
            }
            return (
              <Tooltip title={tooltipTitle} placement="bottom" key={recruitType.key}>
                <Card
                  selected={recruitType.key === recruit.type}
                  disabled={cardDisabled}
                  style={{
                    borderColor:
                      recruitType.key === recruit.type
                        ? configProviderProps.theme.token?.colorPrimary
                        : configProviderProps.theme.token?.colorBorder,
                    gridArea: `item${i + 1}`,
                  }}
                  key={recruitType.key}
                  onClick={() => {
                    if (recruit.status === RECRUIT_STATUSES.NOT_STARTED && !cardDisabled) {
                      changeRecruitType(recruitType.key);
                    }
                  }}
                  className={`card ${recruit.type}`}
                >
                  <span className="icon">{recruitType.icon}</span>
                  <span className="label">{recruitType.label}</span>
                </Card>
              </Tooltip>
            );
          })}
        </div>
      </Styled>
    </div>
  );
};

const Styled = styled.div<{ $cols: number }>`
  .source-title {
    font-weight: 500;
    padding-bottom: 8px;
    font-size: 14px;
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(${props => props.$cols}, 1fr);
    gap: 1rem;
    justify-items: stretch;
    grid-template-areas: "item1 item2 item3 item4 item5";
    max-width: 900px;

    .card {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon {
      text-align: center;
      font-size: 20px;
    }

    .label {
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      padding-left: 0.5rem;
    }
  }
`;

const Card = styled.div.attrs(({ selected, disabled }: { selected: boolean; disabled: boolean }) => ({
  selected,
  disabled,
}))`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  height: 40px;
  border-style: solid;
  border-width: ${({ selected }) => (selected ? 2 : 1)}px;
  padding: 8px 8px;
  border-radius: ${BORDER_RADIUS};
  background-color: white;
  color: ${({ disabled }) => (disabled ? GRAY_6 : "default")};
`;

export default createFragmentContainer(RecruitTypePicker, {
  study: graphql`
    fragment RecruitTypePicker_study on StudyNode {
      id
      incentiveType
      tenant {
        enablePanelView
        isDemoAccount
        voxpopmeGlobal
      }
      recruits {
        edges {
          node {
            id
            type
          }
        }
      }
    }
  `,
  recruit: graphql`
    fragment RecruitTypePicker_recruit on RecruitNode {
      id
      type
      status
      goal
      canHaveVoxpopmeGlobal
    }
  `,
});
