import { CarryOutOutlined, DeploymentUnitOutlined, SolutionOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { useAntdConfig } from "../../antd";
import { ProjectType } from "../../constants";
import { BORDER_RADIUS } from "../../style";
import { useFlag } from "../../utils";
import { maybeElement } from "../../utils/misc";
import { InputTitle } from "./InputTitle";

const ProjectTypePicker = React.forwardRef(({ onChange, value, disabled }: any, ref) => {
  const ffHasSurveyEnabled = useFlag("hub-project-setup-21-01-25");
  const PROJECT_TYPES = [
    {
      key: "OO",
      label: "Interview",
      description: "1on1 interviews with participants",
      icon: <CarryOutOutlined />,
    },
    {
      key: "SS",
      label: "Focus Group",
      description: "Interview everyone at the same time.",
      icon: <DeploymentUnitOutlined />,
    },
    ...maybeElement(ffHasSurveyEnabled, {
      key: "SY",
      label: "Survey",
      description: "General survey without interviews",
      icon: <SolutionOutlined />,
    }),
  ];
  const configProviderProps = useAntdConfig();

  return (
    <Styled>
      <InputTitle text="Project type" />
      <div
        className="inner-card-container"
        style={{
          gridTemplateColumns: ffHasSurveyEnabled ? "1fr 1fr 1fr" : "1fr 1fr",
          gridTemplateAreas: ffHasSurveyEnabled ? "'item1 item2 item3'" : "'item1 item2'",
        }}
      >
        {PROJECT_TYPES.filter(o => (disabled ? o.key === value : true)).map((o, i) => (
          <Tooltip title={o.description} placement="bottom" key={o.key}>
            <Card
              selected={disabled ? false : o.key === value}
              style={{
                borderColor:
                  !disabled && o.key === value
                    ? configProviderProps.theme.token?.colorPrimary
                    : configProviderProps.theme.token?.colorBorder,
                gridArea: `item${i + 1}`,
              }}
              disabled={disabled}
              className="project-type-card"
              key={o.key}
              onClick={() => {
                if (!disabled) onChange(o.key as ProjectType);
              }}
            >
              <div className="icon">{o.icon}</div>
              <div className="label">{o.label}</div>
            </Card>
          </Tooltip>
        ))}
      </div>
    </Styled>
  );
});

const Styled = styled.div`
  padding-bottom: 8px;
  .inner-card-container {
    display: grid;
    gap: 1rem;
    justify-items: start;
    max-width: 900px;

    .project-type-card {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .icon {
        text-align: center;
        font-size: 20px;
      }

      .label {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        padding-left: 0.5rem;
      }
    }
  }
`;

const Card = styled.div.attrs(({ selected, disabled }: { selected: boolean; disabled: boolean }) => ({
  selected,
  disabled,
}))`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  height: 40px;
  border-style: solid;
  border-width: ${({ selected }) => (selected ? 2 : 1)}px;
  padding: 8px;
  border-radius: ${BORDER_RADIUS};
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
`;

export default ProjectTypePicker;
