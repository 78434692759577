import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useUpdateEffect } from "@react-hookz/web";
import { App, Button, Divider, Dropdown, Input, Menu, MenuProps, Switch, Tooltip } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { truncate } from "lodash-es";
import { useMemo, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import CopyToClipboard from "react-copy-to-clipboard";
import { useFragment } from "react-relay";
import styled from "styled-components";

import { ButtonLabel } from "..";
import { PROJECT_STATUSES, SAVING_STATES } from "../../constants";
import { FADED_TEXT_COLOR, GRAY_10, GRAY_7, PAGE_WIDTH_LG } from "../../style";
import { moveEdge, mutation, useFlag, useMutation } from "../../utils";
import type { ProjectScreenerPage_AddElement_Mutation } from "../../__generated__/ProjectScreenerPage_AddElement_Mutation.graphql";
import type { ProjectScreenerPage_ClearLabels_Mutation } from "../../__generated__/ProjectScreenerPage_ClearLabels_Mutation.graphql";
import type { ProjectScreenerPage_CreateRecruitOtp_Mutation } from "../../__generated__/ProjectScreenerPage_CreateRecruitOtp_Mutation.graphql";
import type { ProjectScreenerPage_MoveElement_Mutation } from "../../__generated__/ProjectScreenerPage_MoveElement_Mutation.graphql";
import type { ProjectScreenerPage_screener$key } from "../../__generated__/ProjectScreenerPage_screener.graphql";
import type { ProjectScreenerPage_UpdateScreener_Mutation } from "../../__generated__/ProjectScreenerPage_UpdateScreener_Mutation.graphql";
import ProjectPageContent from "../ProjectPageContent";
import Saving, { type SavingStateType } from "../Saving";

import { Element } from ".";
import { ShareTestScreenerModal } from "./ShareTestScreenerModal";

const ProjectScreenerPage = ({ screener: screenerKey }: { screener: ProjectScreenerPage_screener$key }) => {
  const screener = useFragment(
    graphql`
      fragment ProjectScreenerPage_screener on ScreenerNode {
        id
        title
        description
        study {
          id
          namePublic
          status
          tenant {
            enableThreatDetection
          }
          testRecruit {
            id
            screenerLink
          }
        }
        elements {
          edges {
            node {
              id
              dbId
              position
              text
              label
              ...Element_element
            }
          }
        }
        ...Element_screener
      }
    `,
    screenerKey
  );

  const ffHasScreenerChanges = useFlag("hub-screener-changes-21-01-25");

  const { message } = App.useApp();

  const [showShareModal, setShowShareModal] = useState(false);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0);
  const [showLabels, setShowLabels] = useState(!!screener?.elements?.edges.some(e => e?.node?.label));
  const [savingState, setSavingState] = useState<SavingStateType>("saved");

  const [title, setTitle] = useState(screener?.title ?? "");
  const [titlePrevious, setTitlePrevious] = useState<string | null>(null);

  const activeElementRef = useRef<HTMLDivElement>(null);
  const copyTestLinkClickTargetRef = useRef<HTMLElement>(null);

  const screenerLink = useMemo(
    () =>
      `${screener?.study.testRecruit.screenerLink ?? ""}${
        screener?.study.status === PROJECT_STATUSES.DRAFT ? "?preview" : ""
      }`,
    [screener?.study.status, screener?.study.testRecruit.screenerLink]
  );

  useUpdateEffect(() => {
    activeElementRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [activeQuestionIndex]);

  const updateScreener = async (input: { title?: string; description?: string }) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ProjectScreenerPage_UpdateScreener_Mutation>({
        variables: {
          input: {
            studyId: screener?.study?.id || "",
            ...input,
          },
        },
        mutation: graphql`
          mutation ProjectScreenerPage_UpdateScreener_Mutation($input: UpdateScreenerInput!) {
            updateScreener(input: $input) {
              screener {
                id
                title
                description
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const moveElement = async (input: { elementId: string; fromPosition: number; toPosition: number }) => {
    const newEdges = moveEdge(screener?.elements?.edges || [], input.fromPosition, input.toPosition);

    // Handle cases when the active question needs to move
    if (activeQuestionIndex === input.fromPosition) {
      setActiveQuestionIndex(input.toPosition);
    } else if (activeQuestionIndex < input.fromPosition && activeQuestionIndex >= input.toPosition) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    } else if (activeQuestionIndex > input.fromPosition && activeQuestionIndex <= input.toPosition) {
      setActiveQuestionIndex(activeQuestionIndex - 1);
    }

    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ProjectScreenerPage_MoveElement_Mutation>({
        variables: {
          input: {
            elementId: input.elementId,
            toPosition: input.toPosition,
          },
        },
        mutation: graphql`
          mutation ProjectScreenerPage_MoveElement_Mutation($input: MoveElementInput!) {
            moveElement(input: $input) {
              screener {
                id
                elements {
                  edges {
                    node {
                      id
                      position
                      text
                    }
                  }
                }
              }
            }
          }
        `,
        optimisticResponse: {
          moveElement: {
            screener: {
              id: screener?.id || "",
              elements: {
                edges: newEdges,
              },
            },
          },
        },
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const addElement = async ({ position }: { position: number }) => {
    try {
      setSavingState(SAVING_STATES.SAVING);
      await mutation<ProjectScreenerPage_AddElement_Mutation>({
        variables: {
          input: {
            screenerId: screener?.id || "",
            position: position,
          },
        },
        mutation: graphql`
          mutation ProjectScreenerPage_AddElement_Mutation($input: AddElementInput!) {
            addElement(input: $input) {
              screener {
                id
                elements {
                  edges {
                    node {
                      ...ElementBase_element
                    }
                  }
                }
              }
            }
          }
        `,
      });
      setSavingState(SAVING_STATES.SAVED);
    } catch {
      setSavingState(SAVING_STATES.ERROR);
    }
  };

  const handleShowLabelsChange = async (show: boolean) => {
    const shouldClear = !show && showLabels;
    setShowLabels(show);

    // when turning labels off, clear existing labels
    if (shouldClear) {
      try {
        setSavingState(SAVING_STATES.SAVING);
        await mutation<ProjectScreenerPage_ClearLabels_Mutation>({
          variables: {
            input: {
              studyId: screener!.id,
            },
          },
          mutation: graphql`
            mutation ProjectScreenerPage_ClearLabels_Mutation($input: ClearLabelsInput!) {
              clearLabels(input: $input) {
                screener {
                  elements {
                    edges {
                      node {
                        label
                      }
                    }
                  }
                }
              }
            }
          `,
        });
        setSavingState(SAVING_STATES.SAVED);
      } catch {
        setSavingState(SAVING_STATES.ERROR);
      }
    }
  };

  const [commitCreateRecruitOtp, createRecruitOtpIsInFlight] =
    useMutation<ProjectScreenerPage_CreateRecruitOtp_Mutation>(graphql`
      mutation ProjectScreenerPage_CreateRecruitOtp_Mutation($input: CreateRecruitOTPInput!) {
        createRecruitOtp(input: $input) {
          id
          value
        }
      }
    `);

  const onOpenPreviewClicked = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (screener?.study.tenant.enableThreatDetection) {
      e.preventDefault();
      commitCreateRecruitOtp(
        {
          recruitId: screener.study.testRecruit.id,
        },
        {
          onCompleted: res => {
            if (res.createRecruitOtp) {
              const url = new URL(screenerLink);
              url.searchParams.set("oid", res.createRecruitOtp.id);
              url.searchParams.set("otp", res.createRecruitOtp.value);
              window.open(url.href, "_blank", "noopener,noreferrer");
            } else window.open(screenerLink, "_blank", "noopener,noreferrer");
          },
        }
      );
    }
  };

  const menuItems: MenuProps["items"] = [
    {
      key: "share",
      label: "Share test",
      onClick: () => setShowShareModal(true),
    },
    {
      key: "copy",
      label: "Copy test link",
      onClick: () => {
        if (copyTestLinkClickTargetRef.current) {
          copyTestLinkClickTargetRef.current.click();
        }
      },
    },
    {
      key: "test",
      label: (
        <a href={screenerLink} target="_blank" rel="noopener noreferrer" onClick={onOpenPreviewClicked}>
          Test screener
        </a>
      ),
      disabled: createRecruitOtpIsInFlight,
    },
  ];

  const menu = <Menu items={menuItems} />;

  return (
    <ProjectPageContent
      extra={
        <>
          <ShareTestScreenerModal
            studyId={screener!.study.id}
            visible={showShareModal}
            setVisible={setShowShareModal}
          />
          <Saving savingState={savingState} style={{ position: "initial" }} type="text" />
          {[PROJECT_STATUSES.DRAFT, PROJECT_STATUSES.LIVE].includes(screener!.study.status) && (
            <>
              <Dropdown trigger={["click"]} menu={menu.props}>
                <ButtonLabel icon="ic:outline-screen-search-desktop" text="Test Screener" />
              </Dropdown>

              {/* CopyToClipboard can't appear in a Menu.Item; this works around that limitation with a hidden click target */}
              <div style={{ display: "none" }}>
                <CopyToClipboard
                  text={screener!.study.testRecruit.screenerLink!}
                  onCopy={() => message.success("Link copied to clipboard")}
                >
                  <span ref={copyTestLinkClickTargetRef} />
                </CopyToClipboard>
              </div>
            </>
          )}
        </>
      }
      style={{ padding: 0, maxHeight: "100%", overflowY: "auto" }}
      title={ffHasScreenerChanges ? "Screener" : "Set up a screener"}
    >
      <StyledScreener>
        <div className="description">
          <div className="title">{ffHasScreenerChanges ? "Screener Introduction" : "Title"}</div>
          <div style={{ display: "flex", gap: 8 }}>
            <Input
              placeholder="What is the title of your screener?"
              value={title}
              maxLength={512}
              onBlur={() => {
                if (title !== screener?.title) updateScreener({ title });
              }}
              onChange={e => setTitle(e.target.value)}
            />
            {!!screener?.study?.namePublic && screener.study.namePublic !== screener.title ? (
              <Button
                disabled={savingState === SAVING_STATES.SAVING}
                onClick={() => {
                  if (screener.study.namePublic) {
                    setTitlePrevious(title || screener.title || null);
                    setTitle(screener.study.namePublic);
                    updateScreener({ title: screener.study.namePublic });
                  }
                }}
              >
                Use project name: <b>{truncate(screener.study.namePublic, { length: 20 })}</b>
              </Button>
            ) : (
              !!titlePrevious &&
              titlePrevious !== screener?.title && (
                <Button
                  disabled={savingState === SAVING_STATES.SAVING}
                  onClick={() => {
                    setTitle(titlePrevious);
                    setTitlePrevious(null);
                    updateScreener({ title: titlePrevious });
                  }}
                >
                  Restore previous title: <b>{truncate(titlePrevious, { length: 20 })}</b>
                </Button>
              )
            )}
          </div>
          <p className="input-info">
            {title.length ? "This title is visible " : "If filled out, this title will be visible "}
            in the screener
            {!screener?.study?.namePublic && ", in the subject of emailed screener invites, and in the panelist portal"}
          </p>
          <div className="title">Description</div>
          <Input.TextArea
            placeholder="Enter the welcome text that your potential participants will see at the top of your screener."
            autoSize={{ minRows: 4 }}
            defaultValue={screener?.description ? screener.description : ""}
            maxLength={5000}
            onBlur={e =>
              updateScreener({
                description: e.target.value,
              })
            }
          />
          {ffHasScreenerChanges ? (
            <p className="input-info">
              View formatting options{" "}
              <a href="https://commonmark.org/help/" rel="noopener noreferrer" target="_blank">
                here
              </a>
            </p>
          ) : (
            <p className="input-info">
              Description text supports{" "}
              <a href="https://commonmark.org/help/" rel="noopener noreferrer" target="_blank">
                Markdown
              </a>
            </p>
          )}

          <Divider />

          <div className="title">
            {ffHasScreenerChanges ? (
              <>
                {screener?.elements?.edges.length === 0 ? (
                  <div
                    className="add-button"
                    style={{
                      marginTop: 0,
                      flexGrow: 1,
                    }}
                  >
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setActiveQuestionIndex(0);
                        addElement({ position: 0 });
                      }}
                    >
                      Add screener
                    </Button>
                  </div>
                ) : (
                  <span style={{ flexGrow: 1 }}></span>
                )}
              </>
            ) : (
              <span style={{ flexGrow: 1 }}>Questions</span>
            )}

            <label>
              Show answer labels
              <Tooltip
                title="Labels are for your use only and will not be displayed to participants."
                placement="bottom"
              >
                <span style={{ color: FADED_TEXT_COLOR }}>
                  <QuestionCircleOutlined />
                </span>
              </Tooltip>
              <Switch checked={showLabels} size="small" onChange={handleShowLabelsChange} />
            </label>
          </div>
          {!ffHasScreenerChanges && (
            <>
              {screener?.elements?.edges.length === 0 && (
                <div className="add-button">
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setActiveQuestionIndex(0);
                      addElement({ position: 0 });
                    }}
                  >
                    Add the first screener question
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        <DragDropContext
          onDragEnd={(result: DropResult) => {
            if (typeof result.destination?.index === "number") {
              moveElement({
                elementId: result.draggableId,
                toPosition: result.destination?.index,
                fromPosition: result.source?.index,
              });
            }
          }}
        >
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {screener?.elements?.edges.map((o: any, index: any) => {
                  return (
                    o?.node && (
                      <Draggable key={o.node.id} draggableId={o.node.id} index={o.node.position}>
                        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            id={`screener-element-${o.node.position}`}
                          >
                            {o.node && (
                              <Element
                                ref={index === activeQuestionIndex ? activeElementRef : null}
                                screener={screener}
                                element={o.node}
                                dragHandleProps={provided.dragHandleProps}
                                isActive={index === activeQuestionIndex}
                                setActive={offset => setActiveQuestionIndex(index + (offset ?? 0))}
                                showLabel={showLabels}
                                savingState={savingState}
                                setSavingState={setSavingState}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    )
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </StyledScreener>
    </ProjectPageContent>
  );
};

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  marginTop: "12px",
  scale: isDragging && "8px",
  ...draggableStyle,
});

const StyledScreener = styled.div`
  padding: 24px 24px 48px;
  max-width: ${PAGE_WIDTH_LG};

  .title {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 12px;
  }

  .description {
    margin-bottom: 24px;

    .input-info {
      margin-top: 4px;
      margin-bottom: 14px;
      font-size: 12px;
      color: ${GRAY_7};

      a {
        color: var(--ant-primary-color);
      }
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: ${GRAY_10};
      margin-bottom: 8px;
      gap: 8px;

      label {
        display: flex;
        align-items: center;
        font-weight: normal;
        gap: 4px;
      }
    }
  }

  .add-button {
    margin-top: 24px;
  }

  .exit-links {
    margin-top: 25px;

    div {
      max-width: 600px;

      .content {
        margin-left: 0;
      }

      .inputs {
        margin-top: 20px;

        .ant-input-group {
          margin-bottom: 10px;
        }
      }
    }
  }

  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

export default ProjectScreenerPage;
