import { App } from "antd";
import type { ArgsProps, NotificationInstance } from "antd/es/notification/interface";
import { graphql } from "babel-plugin-relay/macro";
import { useRouter, type Router } from "found";
import { sumBy } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { fetchQuery } from "react-relay";

import { NOTIFICATIONS_POLLING_INTERVAL_SECONDS } from "../../constants";
import { environment } from "../../relay";
import type { DispatchState } from "../../types";
import { pluralize } from "../../utils/misc";
import type {
  NotificationsPoller_remindersQuery,
  NotificationsPoller_remindersQuery$data,
} from "../../__generated__/NotificationsPoller_remindersQuery.graphql";

import { NotificationLink } from "./Notification";
import { NotificationsList } from "./NotificationsList";

type NotificationContext = {
  notification: NotificationInstance;
  router: Router;
  studyIdsNeedingApprovals: string[];
  setStudyIdsNeedingApprovals: DispatchState<string[]>;
  studyIdsNeedingIncentives: string[];
  setStudyIdsNeedingIncentives: DispatchState<string[]>;
};

export const NotificationsPoller = () => {
  const { notification } = App.useApp();
  const { router } = useRouter();
  const [studyIdsNeedingApprovals, setStudyIdsNeedingApprovals] = useState<string[]>([]);
  const [studyIdsNeedingIncentives, setStudyIdsNeedingIncentives] = useState<string[]>([]);

  const context: NotificationContext = useMemo(
    () => ({
      notification,
      router,
      studyIdsNeedingApprovals,
      setStudyIdsNeedingApprovals,
      studyIdsNeedingIncentives,
      setStudyIdsNeedingIncentives,
    }),
    [notification, router, studyIdsNeedingApprovals, studyIdsNeedingIncentives]
  );

  useEffect(() => {
    // call once immediately before we start polling, unless we've just triggered a re-render
    if (context.studyIdsNeedingApprovals.length === 0 && context.studyIdsNeedingIncentives.length === 0) {
      refreshNotifications(context);
    }

    const pollInterval = window.setInterval(
      () => refreshNotifications(context),
      NOTIFICATIONS_POLLING_INTERVAL_SECONDS * 1000
    );

    // cleanup on navigate away or sign out
    return () => window.clearInterval(pollInterval);
  }, [context]);

  return null;
};

const NOTIFICATION_PROPS = {
  // keep approve/incentivize participant notifications up for 15 seconds
  duration: 15,
} as const as Partial<ArgsProps>;

// fetch and display notifications
async function refreshNotifications(context: NotificationContext) {
  try {
    const results = await fetchNotifications();
    if (results) {
      handleNotifications(results, context);
    }
  } catch (err) {
    console.error(err);
  }
}

// refresh notifications from server
function fetchNotifications(): Promise<NotificationsPoller_remindersQuery$data | undefined> {
  return fetchQuery<NotificationsPoller_remindersQuery>(
    environment,
    graphql`
      query NotificationsPoller_remindersQuery {
        viewer {
          notifications {
            studiesNeedingApprovals {
              study {
                id
                name
              }
              approvalsNeeded
            }
            studiesNeedingIncentives {
              study {
                id
                name
              }
              incentivesNeeded
            }
          }
        }
      }
    `,
    {},
    null
  ).toPromise();
}

// handle result for notifications query
function handleNotifications(result: NotificationsPoller_remindersQuery$data, context: NotificationContext) {
  const notifications = result.viewer?.notifications;
  if (!notifications) {
    return;
  }

  if (notifications.studiesNeedingApprovals?.length) {
    handleStudiesNeedingApprovals(
      notifications.studiesNeedingApprovals.map(s => ({
        id: s.study.id,
        name: s.study.name ?? "<unnamed study>",
        participants: s.approvalsNeeded,
      })),
      context
    );
  }

  if (notifications.studiesNeedingIncentives?.length) {
    handleStudiesNeedingIncentives(
      notifications.studiesNeedingIncentives.map(s => ({
        id: s.study.id,
        name: s.study.name ?? "<unnamed study>",
        participants: s.incentivesNeeded,
      })),
      context
    );
  }
}

function handleStudiesNeedingApprovals(
  studiesNeedingApprovals: { id: string; name: string; participants: number }[],
  context: NotificationContext
) {
  const newStudiesNeedingApprovals = studiesNeedingApprovals.filter(
    s => !context.studyIdsNeedingApprovals.includes(s.id)
  );
  if (!newStudiesNeedingApprovals.length) return;

  // don't spam if there are multiple new notifications
  if (newStudiesNeedingApprovals.length === 1)
    context.notification.warning({
      message: `You have ${pluralize(
        newStudiesNeedingApprovals[0]!.participants,
        `${newStudiesNeedingApprovals[0]!.participants.toLocaleString()} participants`,
        "a participant"
      )} to approve`,
      description: (
        <>
          Go to the <strong>{newStudiesNeedingApprovals[0]!.name}</strong>{" "}
          <NotificationLink
            router={context.router}
            to={`/projects/${newStudiesNeedingApprovals[0]!.id}/respondents/participants`}
          >
            Participants page
          </NotificationLink>{" "}
          to approve them
        </>
      ),
      placement: "bottomRight",
      ...NOTIFICATION_PROPS,
    });
  else {
    const totalParticipants = sumBy(newStudiesNeedingApprovals, val => val.participants);

    context.notification.warning({
      message: `You have ${pluralize(
        totalParticipants,
        `${totalParticipants.toLocaleString()} participants`,
        "one participant"
      )} to approve`,
      description: (
        <>
          <p>Visit the participants pages to approve them</p>
          <NotificationsList notifications={newStudiesNeedingApprovals} router={context.router} />
        </>
      ),
      placement: "bottomRight",
      ...NOTIFICATION_PROPS,
    });
  }

  context.setStudyIdsNeedingApprovals([
    ...context.studyIdsNeedingApprovals,
    ...newStudiesNeedingApprovals.map(s => s.id),
  ]);
}

function handleStudiesNeedingIncentives(
  studiesNeedingIncentives: { id: string; name: string; participants: number }[],
  context: NotificationContext
) {
  const newStudiesNeedingIncentives = studiesNeedingIncentives.filter(
    s => !context.studyIdsNeedingIncentives.includes(s.id)
  );
  if (!newStudiesNeedingIncentives.length) return;

  // don't spam if there are multiple new notifications
  if (newStudiesNeedingIncentives.length === 1) {
    context.notification.warning({
      message: `You have ${pluralize(
        newStudiesNeedingIncentives[0]!.participants,
        `${newStudiesNeedingIncentives[0]!.participants.toLocaleString()} participants`,
        "a participant"
      )} to incentivize`,
      description: (
        <>
          Go to the <strong>{newStudiesNeedingIncentives[0]!.name}</strong>{" "}
          <NotificationLink
            router={context.router}
            to={`/projects/${newStudiesNeedingIncentives[0]!.id}/respondents/participants`}
          >
            Participants page
          </NotificationLink>{" "}
          to incentivize them
        </>
      ),
      placement: "bottomRight",
      ...NOTIFICATION_PROPS,
    });
  } else {
    const totalParticipants = sumBy(newStudiesNeedingIncentives, val => val.participants);

    context.notification.warning({
      message: `You have ${pluralize(
        totalParticipants,
        `${totalParticipants.toLocaleString()} participants`,
        "one participant"
      )} to incentivize`,
      description: (
        <>
          <p>Visit the participants pages to incentivize them</p>
          <NotificationsList notifications={newStudiesNeedingIncentives} router={context.router} />
        </>
      ),
      placement: "bottomRight",
      ...NOTIFICATION_PROPS,
    });
  }

  context.setStudyIdsNeedingIncentives([
    ...context.studyIdsNeedingIncentives,
    ...newStudiesNeedingIncentives.map(s => s.id),
  ]);
}
